import React from 'react';
import tw from 'twin.macro';

export default function Loader({ width }) {
  return (
    <svg tw="mx-auto text-center" width={width} height={width} version="1.1" id="L4" x="0px" y="0px" viewBox="0 0 50 100" enable-background="new 0 0 0 0">
      <circle fill="#333" stroke="none" cx="6" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>    
      </circle>
      <circle fill="#333" stroke="none" cx="26" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate>       
      </circle>
      <circle fill="#333" stroke="none" cx="46" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate>     
      </circle>
    </svg>
  )
}
