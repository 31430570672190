import React, { useState } from "react"
import tw from "twin.macro"
import { useFormik } from "formik"
import * as yup from "yup"
import axios from "axios"
import constants from "../constants"

export default function ContactUsForm() {
  const [isSending, setIsSending] = useState(false)
  const [success, setSuccess] = useState({ isSent: false, status: false });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      purpose: "",
      msg: "",
    },
    validationSchema: yup.object().shape({
      firstname: yup.string().required("Please enter your first name"),
      lastname: yup.string().required("Please enter your last name"),
      email: yup
        .string()
        .email("email is not valid")
        .required("Please enter your email"),
      purpose: yup
        .string()
        .required("please choose why are you sending this message"),
      msg: yup
        .string()
        .required("write your message")
        .max(2000, "your message is too long, try to make short please."),
    }),
    onSubmit: async (vals, {resetForm}) => {
      setIsSending(true)
      try {
        const resp = await axios.post(`${constants.API_URL}/contacts`, vals)
        if(resp.status === 200){
          setSuccess({
            isSent: true,
            status: true
          });
          resetForm();
        }
      } catch (err) {
        console.log(err)
        setSuccess({
          isSent: true,
          status: false
        });
      }
      setIsSending(false)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div tw="grid grid-cols-6 gap-6">
        <div tw="col-span-6 sm:col-span-3">
          <label tw="block text-sm font-medium text-gray-700">First name</label>
          <input
            type="text"
            name="firstname"
            onChange={formik.handleChange}
            value={formik.values.firstname}
            autoComplete="off"
            tw="mt-1 p-2 focus:outline-none focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded"
          />
          <small tw="text-red-500 block">{formik.errors.firstname}</small>
        </div>
        <div tw="col-span-6 sm:col-span-3">
          <label tw="block text-sm font-medium text-gray-700">Last name</label>
          <input
            type="text"
            name="lastname"
            onChange={formik.handleChange}
            value={formik.values.lastname}
            autoComplete="off"
            tw="mt-1 p-2 focus:outline-none focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded"
          />
          <small tw="text-red-500 block">{formik.errors.lastname}</small>
        </div>
        <div tw="col-span-6">
          <label tw="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <input
            type="text"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            autoComplete="off"
            tw="mt-1 p-2 focus:outline-none focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded"
          />
          <small tw="text-red-500 block">{formik.errors.email}</small>
        </div>
        <div tw="col-span-6">
          <label tw="block text-sm font-medium text-gray-700">What for ?</label>
          <select
            onChange={formik.handleChange}
            value={formik.values.purpose}
            name="purpose"
            tw="mt-1 p-2 focus:outline-none focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded"
          >
            <option selected disabled value="">
              Choose something...
            </option>
            <option value="I have question"> I have question </option>
            <option value="I want to contribute (help)">
              I want to contribute (volunteer)
            </option>
            <option value="I just want to say hi">
              I just want to say hi
            </option>
          </select>
          <small tw="text-red-500 block">{formik.errors.purpose}</small>
        </div>
        <div tw="col-span-6">
          <label tw="block text-sm font-medium text-gray-700">
            Your message
          </label>
          <textarea
            name="msg"
            onChange={formik.handleChange}
            rows="4"
            value={formik.values.msg}
            tw="mt-1 p-2 focus:outline-none focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded"
          ></textarea>
          <small tw="text-red-500 block">{formik.errors.msg}</small>
        </div>
        <div tw="col-span-6">
          {isSending ? (
            <button
              type="button"
              tw="bg-blue-500 opacity-50 text-white font-medium text-center block w-full mt-5 px-2 py-3 rounded hover:bg-blue-600 transition"
            >
              Sending...
            </button>
          ) : (
            <button
              type="submit"
              tw="bg-blue-500 text-white font-medium text-center block w-full mt-5 px-2 py-3 rounded hover:bg-blue-600 transition"
            >
              Send it
            </button>
          )}

          {
            success.isSent ? (
              success.status ? (
                <p tw="text-center mt-3 text-green-500">
                  Your message is successfully sent, we will get back to you as soon as possible. Thanks 😄
                </p>
              ) : (
                <p tw="text-center mt-3 text-pink-600">
                  Something went wrong while delivering your message, please try again later. Thanks 😁
                </p>
              )
            ) : null
          }
        </div>
      </div>
    </form>
  )
}
