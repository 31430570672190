import React from "react"
import tw, { styled } from "twin.macro"
import ContactUsForm from "./contactUsForm"

const Wrapper = styled.footer`
  padding: 5rem 1rem 1rem;
  background: #07288e;
  @media (min-height: 759px) and (min-width: 700px) {
    padding-top: 7rem;
    height: 100%;
  }
  position: relative;
`

const ContentWrapper = styled.div`
  ${tw`max-w-6xl mx-auto`}
`

export default function Footer() {
  return (
    <Wrapper id="contact">
      <ContentWrapper>
        <div tw="grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-4">
          <div tw="mb-4 row-span-2">
            <span tw="block font-medium text-2xl md:text-4xl text-gray-200">
              We would be so glad to hear good words from you.
            </span>
            <div tw="mt-5">
              <p tw="text-blue-200">
                "Do you not see how Allah compares a good word to a good tree?
                Its root is firm and its branches reach the sky, ˹always˺
                yielding its fruit in every season by the Will of its Lord. This
                is how Allah sets forth parables for the people, so perhaps they
                will be mindful, And the parable of an evil word is that of an
                evil tree, uprooted from the earth, having no stability."
                <a
                  tw="text-sm inline-block ml-2 text-blue-400 hover:text-blue-500"
                  href="https://quran.com/14"
                  target="_blank"
                  rel="noreferrer"
                >
                  Full surah...
                </a>
              </p>
              <i tw="text-blue-300"> — Surat Ibrahim</i>
            </div>
          </div>
          <div>
            <div tw="bg-white px-4 pt-8 pb-5 rounded-md shadow-md">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </ContentWrapper>
      <div tw="mt-16 border-t border-blue-800 pt-3 px-2">
        <div tw="flex items-center justify-center">
          <a href="/" tw="text-sm text-gray-300 hover:text-gray-400 mx-2"> Home </a>
          {/* <a href="/privacy" tw="text-sm text-gray-300 hover:text-gray-400 mx-2"> Privacy </a>
          <a href="/terms" tw="text-sm text-gray-300 hover:text-gray-400 mx-2"> Terms </a> */}
        </div>
      </div>
    </Wrapper>
  )
}
